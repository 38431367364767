<template>
  <!--:rowKey="record => record.key"-->
  <!--@expand="expandBtn"-->
  <div class="tables" style="background: #ffffff">
    <a-table v-if="data" :columns="cloumns" :dataSource="data" bordered>
      <div slot="var_rate" slot-scope="text, record">
        {{ record.var_rate }}
      </div>
    </a-table>
  </div>
</template>

<script>
function fmtRatio (val, index, def) {
  // index默认值为2
  var index = arguments[1] ? arguments[1] : 2
  if (val == null || isNaN(val) || (typeof val === 'string' && val === '')) {
    return def || '--'
  }
  var ratio = (val * 100).toFixed(index)
  return ratio + '%'
}
export default {
  name: 'tables',

  data () {
    return {
      pic95:
        'https://smart-resource.sikegroup.com/50f5dc040eee603ee12ce71806e3f5be.png',
      pic99:
        'https://smart-resource.sikegroup.com/50f5dc040eee603ee12ce71806e3f5be.png',
      cloumns: [
        {
          title: '置信度',
          dataIndex: 'var_rate ',
          class: 'var_rate',
          scopedSlots: { customRender: 'var_rate' },
          customRender: (value, row, index) => {
            let obj = {
              children: 222,
              attrs: {}
            }
            if (index === 1) {
              obj = {
                children: value,
                attrs: {
                  rowSpan: 4
                }
              }
            }
            if (index === 5) {
              obj = {
                children: index,
                attrs: {
                  rowSpan: 5
                }
              }
            }
            if ([0, 2, 3, 4, 6, 7, 8, 9, 10].indexOf(index) !== -1) {
              obj.attrs.colSpan = 0
            }
            return obj
          }
        },
        {
          title: '天数',
          dataIndex: 'window_length',
          width: '25%'
          // customRender: (text) => text + '日'
        },
        {
          title: 'VaR(万元)',
          dataIndex: 'var',
          width: '25%'
        }
        // {
        //     title: 'VaR/净资产',
        //     dataIndex: 'var_rate',
        //     width: '25%',
        //     // customRender: (text) => fmtRatio(text, 2)
        // }
      ],
      data: [
        { window_length: 1, var: 151.69, var_rate: 0.01858 },
        { window_length: 5, var: 298.94, var_rate: 0.03661 },
        { window_length: 10, var: 416.7, var_rate: 0.05104 },
        { window_length: 20, var: 576.04, var_rate: 0.07055 },
        { window_length: 1, var: 370.64, var_rate: 0.045398 },

        { window_length: 5, var: 463.33, var_rate: 0.056751 },
        { window_length: 10, var: 632.91, var_rate: 0.077523 },
        { window_length: 20, var: 1233.95, var_rate: 0.15114 },
        { window_length: 20, var: 1233.95, var_rate: 0.15114 }
        // {window_length: 20, var: 1233.95, var_rate: 0.15114},
      ]
    }
  },
  created () {
    console.log(this.cloumns)

    let self = this
    // this.$axios.get(1051,{
    //
    // },res=>{
    //     self.data = res.data;
    //     console.log(self.data);
    // })
  },
  methods: {
    // expandBtn(record, index){
    //     //console.log(record);
    //     let keys = index.key-1;
    //     if(record){
    //         console.log(this.data[keys]['children']);
    //         // this.data[keys]['children'].push(this.childrenData);
    //         console.log(this.data[keys]);
    //         //this.data.push(this.childrenData);
    //     }
    //     console.log(index);
    //     //console.log(record);
    // }
  }
}
</script>

<style lang="less" scoped></style>
